import React from "react";
import PropTypes from "prop-types";

import { Box, Container, Typography } from "@mui/material";
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';

import {
	StrategyContext, defaultStrategyContext
} from "../../context/StrategyContext.js"

import { setAuthToken } from "../../lib/strategy-ops.js";
import { YEAR_MS } from "../../lib/duration-ops.js";

import Banner from "./Banner.js";
import Demo from "./Demo.js";
import LoadingModal from "../LoadingModal.js";

import "../../css/landing-page.css";

const { REACT_APP_DEMO_TOKEN } = process.env;

class LandingPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"context_data": defaultStrategyContext,
			"disable_demo" : true
		};

		this.updateStrategyContext = this.updateStrategyContext.bind(this);
	}

	static propTypes = {
		"match" : PropTypes.object.isRequired,
		"history" : PropTypes.object.isRequired,
		"location" : PropTypes.object.isRequired
	}

	componentDidMount() {
		console.log("Setting demo credentials.")

		/* Activate demo credentials. */
		const expires_at = new Date().getTime() + YEAR_MS;
		setAuthToken(REACT_APP_DEMO_TOKEN, expires_at);
	}

	componentWillUnmount() {
		console.log("Clearing demo credentials");
		setAuthToken(null, null);
	}

	render() {
		const { history } = this.props;
		const { disable_demo, context_data } = this.state;
		const { is_loading, loading_failed, loading_msg } = context_data;

		return (
			<Box className={"landing-page"}>
				<Banner
					onBtn1={
						disable_demo ? () => {
							this.setState({"disable_demo" : false});
						} : undefined
					}
					onBtn2={() => {
						history.push("/about");
					}}
				/>

				<StrategyContext.Provider value={context_data}>
				{
					disable_demo ?
					<Box
						title="Try Demo"
						className={"landing-stepper-container-demo-box"}
						onClick={() => {
							this.setState({"disable_demo" : false});
						}}
					>
						<BuildCircleOutlinedIcon
							color={"secondary"}
							className={"landing-stepper-container-demo-icon"}
						/>
						<Typography color="secondary.main"
							sx={{fontWeight: "bold"}}
							variant="h1"
							className={"landing-stepper-container-demo-txt"}
						>
							Try a Demonstration
						</Typography>
					</Box>
					:
					<Container
						className={"landing-stepper-container"}
						sx={{"backgroundColor":"background.paper"}}
					>
						<Demo
							setContext={this.updateStrategyContext}
							is_disabled={disable_demo}
						/>
					</Container>
				}
					<LoadingModal
						is_open={is_loading && !loading_failed}
						message={loading_msg}
						allow_timeout={false}
						/>
				</StrategyContext.Provider>
			</Box>
		);
	}


	/**
	 * Ensures that whatever updates were done to the context are given a new object reference.
	 */
	updateStrategyContext (new_context, cb=undefined) {
		const old_context = this.state.context_data;
		// Ensures that new context will have new reference object.
		const context_data = { ...old_context, ...new_context };

		this.setState({ context_data }, cb);
	}
}

export default LandingPage;
