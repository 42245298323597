import React from "react";
import PropTypes from "prop-types";

import {
	Box, Button, Divider, IconButton,
	Tooltip, TextField, Typography
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";

import axios from "axios";
import log from "loglevel";
import async from "async";

import CenterModal from "../CenterModal.js";
import StrategyBuilder from "./StrategyBuilder.js";
import SimulationTable from "./SimulationTable.js";
import SimulationParameters from "./SimulationParameters.js";
import {
	StrategyContext, NUMBER_RGX, updateLeafSimulations
} from "../../context/StrategyContext.js";
import {
	updateStrategy, deleteStrategy, CONTINUOUS_POLL_DELAY, getStrategySimulations
} from "../../lib/strategy-ops.js";
import TemplateEditor from "./template-designer/TemplateEditor.js";

class StrategyDesigner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"is_simulating" : false,
			"is_renaming" : false,
			"show_delete" : false,
			"can_save" : false,
			"new_strategy_title" : "",
			"poll_interval" : null
		};

		this.handleTestFileUpload = this.handleTestFileUpload.bind(this);
		this.handleInputUpdate = this.handleInputUpdate.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"match" : PropTypes.object.isRequired,
		"history" : PropTypes.object.isRequired,
		"location" : PropTypes.object.isRequired
	};

	componentDidMount() {
		log.debug("StrategyDesigner componentDidMount");
		const poll_interval = setInterval(() => {
			const {
				sim_page, sim_page_size,
				metric_sort_col, metric_sort_direction,
				selected_leaf
			} = this.context;
			const sid = selected_leaf["id"];

			getStrategySimulations(sid,
									sim_page,
									sim_page_size,
									metric_sort_col,
									metric_sort_direction,
									async.apply(updateLeafSimulations, this.context)
								);
		}, CONTINUOUS_POLL_DELAY);

		this.setState({ poll_interval });
	}
	componentWillUnmount() {
		const { poll_interval } = this.state;

		clearInterval(poll_interval);
	}
	componentDidUpdate() {
		log.debug("StrategyDesigner componentDidUpdate");
	}

	render() {
		const { selected_leaf, groups, setContext, simulations } = this.context;
		const { is_renaming, show_delete, can_save, new_strategy_title } = this.state;

		const is_ready = !!selected_leaf;
		if (!is_ready) return <div />

		const strategy_id = selected_leaf["id"];
		const group_id = selected_leaf["group_id"];

		// Determines if current strategy is a template.
		// TODO: How often is this branch executed?
		if (!group_id) return (<div />);

		const group_type =  groups.length === 0 ? "custom" : groups.filter(g => g.id === group_id)[0]["type"];
		const is_template = group_type === "template";
		const template = is_template ? selected_leaf?.payload?.params?.template : undefined;

		/**
		 * is_dummy = is_template || is_forwardtest
		 * is_forwardtest = selected_leaf.is_continuous
		 */
		const is_dummy = is_template || selected_leaf.is_continuous;
		const is_forwardtest = is_dummy && (!!selected_leaf?.payload?.submit_interval && !selected_leaf?.payload?.params?.number_of_strategies);

		let simulations_arr = simulations[`strategy-${strategy_id}`] || [];

		return (
			<Box className="flex-col-center" sx={{"width":"100%"}}>
				<Box className="dashboard-box flex-col-center">
				<Box className="strategy-rename-box">
					{
						is_renaming ? (
							<Box className="flex-row-space-between">
								<TextField
									type="text"
									label={selected_leaf.title}
									size="small"
									onChange={evt => {
										this.setState({
											"new_strategy_title" : evt.target.value
										});
									}}
									error={false}
									helperText="" />

								<Tooltip title="Save">
									<IconButton
										onClick={() => {
											selected_leaf["title"] = new_strategy_title;
											setContext({selected_leaf}, this.handleSave);
										}}
									>
										<SaveIcon color="success" fontSize="medium"/>
									</IconButton>
								</Tooltip>
								<Tooltip title="Cancel">
									<IconButton
										onClick={()=>{
											this.setState({
												"is_renaming":false,
												"new_group_name" : null
											})
										}}
									>
										<ClearIcon color="error" fontSize="medium"/>
									</IconButton>
								</Tooltip>
							</Box>
						) : (
							<Box className="flex-row-space-between" sx={{"width":"100%"}}>
								<Box className="flex-row-center">
								<Typography color="font.main" variant="h1">{selected_leaf.title}</Typography>
								<Tooltip title="Rename">
									<IconButton onClick={()=>{this.setState({"is_renaming":true})}}>
										<EditIcon color="primary" fontSize="medium"/>
									</IconButton>
								</Tooltip>
								</Box>
								<Box className="flex-row-center">
									{
										is_dummy &&
										<Typography
											color="primary.main"
											variant="h3"
										>
											{
												is_forwardtest ?
													"Forward Test Detected"
													:
													"Template Strategy Detected"
											}
										</Typography>
									}
									{
										!is_dummy && can_save &&
										<Tooltip title="Save Strategy">
											<IconButton
												onClick={() => {
													setContext({selected_leaf}, this.handleSave);
												}}
											>
												<SaveIcon color="success" fontSize="medium"/>
											</IconButton>
										</Tooltip>
									}
									<Tooltip title="Delete Strategy">
										<IconButton
											onClick={() => {
												this.setState({"show_delete" : true});
											}}
										>
											<DeleteIcon color="error" fontSize="medium"/>
										</IconButton>
									</Tooltip>
								</Box>
							</Box>
						)
					}
				</Box>

				<Divider sx={{"width":"100%"}}/>

				<SimulationParameters
					is_disabled={can_save}
					group_id={is_template ? group_id : null}
					strategy_id={strategy_id}
				/>

				{ ((is_template && template) || !is_dummy) && <Divider sx={{"width":"100%", "marginBottom" : "30px"}}/> }

				{
					is_template && template &&
					<TemplateEditor
						is_disabled={true}
						template={template}
						onChange={() => {}}
					/>
				}

				{ ((is_template && template) && !is_dummy) && <Divider sx={{"width":"100%"}}/> }

				{
					(is_forwardtest || !is_dummy) &&
					<Box className="strategy-builder flex-col-center">
						<Typography
							color="font.main"
							variant="h2"
							sx={{
								"textAlign" : "left",
								"width" : "100%"
							}}
						>Strategy Builder</Typography>
						<Box sx={{ "width" : "100%", "padding" : "10px"}}>
							<StrategyBuilder
								is_dummy={is_dummy}
								onChange={this.handleInputUpdate}
								onTest={this.handleTestFileUpload}
							/>
						</Box>
					</Box>
				}

				{ ((is_template && template) || !is_dummy) && <Divider sx={{"width":"100%"}}/> }

				<SimulationTable simulations={simulations_arr} />
			</Box>
				{
					show_delete &&
					<CenterModal
						is_open={show_delete}
						title="Delete Strategy"
						onClose={() => {
							this.setState({"show_delete" : false});
						}}
					>
						<Typography
							color="font.main"
							variant="subtitle1"
						>Delete your strategy?</Typography>

						<Typography
							color="font.main"
							variant="body1"
						>
							This action is cannot be undone. All of your strategy settings and simulations under this strategy will be lost.
						</Typography>

						<Button
							variant="contained"
							color="error"
							sx={{
								"margin" : "20px",
								"color" : "font.button"
							}}
							onClick={this.handleDelete}
						>Confirm Delete</Button>
					</CenterModal>
				}
			</Box>
		);
	}

	handleSave() {
		const { selected_leaf, strategies, setContext } = this.context;

		const gk = `group-${selected_leaf.group_id}`

		// Convert keys to numbers instead of strings.
		// TODO: This is pretty janky, should be handled in the StrategyBuilder
		const cnvt_keys = ["stop_loss", "take_profit", "spread_limit"];
		for (const k of cnvt_keys) {
			const s = selected_leaf[k];
			selected_leaf[k] = isNaN(s) ? -1.0 : Number(selected_leaf[k]);
		}

		strategies[gk] = [
			...strategies[gk].filter(s => s.id !== selected_leaf.id),
			selected_leaf
		];
		
		setContext({
			"is_loading" : true,
			"loading_msg" : "Saving strategy...",
			"strategies" : strategies
		});

		// Stores strategy updates in SQL, simulates strategy, and calculates metrics.
		// TODO: new_strat_id is deprecated and shouldn't be used anywehere'
		updateStrategy(selected_leaf, err => {
			if (err) return log.error(err);

			this.setState({"is_renaming" : false, "can_save" : false}, () => {
				setContext({
					selected_leaf,
					"is_loading" : false,
					"loading_msg" : null,
					"snackbar_msg" : "Saved Strategy",
					"snackbar_sev" : "success"
				});
			});
		});
	}

	/**
	 * Delete the currently selected strategy.
	 * Selects the parent group as the current node for the list.
	 * Removes the corresponding strategy from 'strategies' in the context.
	 */
	handleDelete() {
		const {
			selected_leaf, strategies, groups,
			setContext, setPage
		} = this.context;
		const { "id" : strategy_id, group_id } = selected_leaf;

		setContext({
			"is_loading" : true,
			"loading_msg" : "Deleting strategy...",
		});

		const arr = groups.filter(g => g.id === group_id);
		if (arr.length !== 1) {
			log.error(`Multiple groups found with id=${group_id}`);
			return;
		}

		const parent_group = arr[0];
		const k = `group-${parent_group.id}`;
		strategies[k] = strategies[k].filter(s => s.id !== strategy_id);

		let fin_context = {
			strategies,
			"is_loading" : false,
			"loading_msg" : null,
			"snackbar_msg" : "Strategy Deleted",
			"snackbar_sev" : "success"
		};
		async.series([
			async.apply(deleteStrategy, strategy_id),
			async.apply(setPage, `/dashboard/group-${parent_group.id}`),
			async.apply(setContext, fin_context)
		], (err, results) => {
			if (err) log.error(err);
		});
	}

	/**
	 * TODO: This needs to be modified to work with the new comapre endpoint.
	 */
	handleTestFileUpload(accuracy_values, file_data, cb) {
		const { "provider_name" : data_provider, symbol } = this.state.strategy.simulation_data.security;
		
		accuracy_values.start_time = "2019-01-01T00:00";
		accuracy_values.stop_time = "2019-12-31T00:00";

		if (accuracy_values.start_time && accuracy_values.stop_time) {
			accuracy_values.start_time = parseInt(new Date(accuracy_values.start_time).getTime());
			accuracy_values.stop_time = parseInt(new Date(accuracy_values.stop_time).getTime());
		}

		axios({
			method : "POST",
			url : "/fin/indicator/test",
			headers : {
				"Content-Type" : "text/csv"
			},
			params : { ...accuracy_values, data_provider, symbol },
			data : file_data
		}).then(res => {
			let series = {}
			for (let k of Object.keys(res.data.actual_values)) {
				series[`${k}-actual`] = res.data.actual_values[k];
			}
			for (let k of Object.keys(res.data.expected_values)) {
				series[`${k}-expected`] = res.data.expected_values[k];
			}

			return cb(undefined, {
				"timestamps" : res.data.timestamps,
				series,
				"average_deltas" : res.data.average_deltas,
				"deltas" : res.data.deltas
			});
		}).catch(err => {
			return cb(err);
		});

	}
	/**
	 * This function is called on new updates of the StrategyBuilder component.
	 * It is passed to the StrategyBuilder as the "onChange" prop.
	 * 
	 * @param {*} new_strategy The updated strategy object. 
	 */
	strategyUpdate(new_strategy) {
		this.setState({"strategy" : new_strategy});
	}

	/**
	 * Updates the state with new strategy input. This funtion ensures that the
	 * strategy stored in the state updated on each edit.
	 *
	 * @param {*} evt The event that was triggered for the updated field.
	 */
	handleInputUpdate(evt, cb=null) {
		const { name, value } = evt.target;
		let { selected_leaf, setContext } = this.context;
		const onDone = (leaf) => {
			this.setState({ "can_save" : true }, () => {
				setContext({ "selected_leaf" : leaf });
			}, cb);
		}

		switch(name) {
			case "signals":
				selected_leaf["entrance_signals"] = value.filter(s => {
					return s.entrance_or_exit === "N";
				});
				selected_leaf["exit_signals"] = value.filter(s => {
					return s.entrance_or_exit === "X";
				});
				onDone(selected_leaf);
				break;
			default:
				if (value !== "" && !NUMBER_RGX.test(value)) return;
				selected_leaf[name] = Number(value);
				onDone(selected_leaf);
				break;
		}
	}
}

export default StrategyDesigner;
