import React from "react";
import PropTypes from "prop-types";

import { Box, Button, Typography, Grid, Paper } from "@mui/material";

import log from "loglevel";

import { AccountContext } from "../../context/AccountContext.js";
import Checkout from "./Checkout.js";
import CenterModal from "../CenterModal.js";

import "../../css/braintree.css";

/**
 * TODO: Research compatibility of ES6 on different browsers.
 */
class MembershipSelection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"show_checkout" : false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.renderProBenefits = this.renderProBenefits.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"onMembershipUpdate" : PropTypes.func.isRequired
	};

	render() {
		const { email } = this.context;
		const { onMembershipUpdate } = this.props;
		const { show_checkout } = this.state;

		const has_email = !!email;

		return (
			<Box
				className="flex-row-center"
				style={{"width":"100%"}}
			>
				{
					show_checkout ?
						<Checkout />
						:
						(
							<Box className="flex-col-center upgrade-box">
								<Typography
									color="font.main"
									className="upgrade-text"
									variant="subtitle1"
								>Upgrade to StratSim Pro</Typography>

								<Typography
									color="font.main"
									className="upgrade-text"
									variant="body2"
								>
									You are currently using the Demo version of StratSim, which has limited functionality.
									With StratSim Pro, you gain the ability to generate thousands of strategies based on templates you design.
									You can also have your best strategies monitored for when they would have traded in real time.
								</Typography>

								<Grid
									className="pro-benefits-grid"
									container
									spacing={0}
								>
									{ this.renderProBenefits() }
								</Grid>

								{/* TODO: Add a <Select /> here for the different billing cycle options: 1 month, 6 month, 1 year? */}

								<Button
									className="upgrade-btn"
									variant="contained"
									size="large"
									sx={{"color":"font.button"}}
									disabled={!has_email}
									onClick={() => {
										this.setState({"show_checkout" : true});
									}}
								>{ has_email ? "Upgrade to StratSim Pro" : "Unverified Email"}</Button>
							</Box>
						)
				}
			</Box>
		);
	}

	/**
	 * TODO:
	 * 	need to update name_valid whenever the first_name or last_name is updated.
	 * @param {*} evt
	 */
	handleChange(evt) {
		const { name, value } = evt.target;

		this.setState({ [name] : value });
	}

	renderProBenefits() {
		const arr = [
			{"title" : "Unlimited Daily Simulations", "desc" : "Demo Accounts are limited to 100 Simulations per day."},
			{"title" : "Design Strategy Templates", "desc" : "Templates allow you to specify guidelines for generating new strategies"},
			{"title" : "Continuous Engine Submission", "desc" : "Automatically submit templates and strategies to the engine, hands free."},
			{"title" : "Automated Forward Testing", "desc" : "Satisfied with a strategy? See how it performs in real time on real data."},
			{"title" : "Optional Trade Notifications", "desc" : "Connect your phone or email and receive live updates on your templates and forward tests."},
			{"title" : "Access to Continuous Monitoring Tool ", "desc" : "A separate dashboard specifically for monitoring continuous submissions."}
		];

		const grid_item_props = {
			"className" : "pro-benefits-tile",
			"item" : true,
			"component" : Paper,
			"xs" : 4
		};

		return arr.map(a => {
			return (
				<Grid key={a["title"]} { ...grid_item_props }>
					<Typography variant="body2" color="secondary">{a["title"]}</Typography>
					<Typography color="font.main" variant="body3">{a["desc"]}</Typography>
				</Grid>
			);
		});
	}
}

export default MembershipSelection;
