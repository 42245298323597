import React from "react";
import PropTypes from "prop-types";

import { Box, Button, TextField, Typography, Checkbox } from "@mui/material";
import log from "loglevel";

import { AccountContext } from "../../context/AccountContext.js";
import { isValidPhoneNumber } from "../../lib/string-validation.js";
import { updatePhone, sendVerificationToken } from "../../lib/account-ops.js";

class UpdatePhone extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"is_open" : false,
			"new_phone" : null,
			"verification_code" : null,
			"verification_pending" : false,
			"veriifcation_code_bad" : false,
			"terms_checked" : false,
			"verification_ttl" : 0,
			"verification_expiration" : 0,
			"verification_interval" : null
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSendVerification = this.handleSendVerification.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"onSubmit" : PropTypes.func.isRequired
	};

	render() {
		const {
			is_open, new_phone, terms_checked,
			verification_pending, verification_code_bad, verification_ttl
		} = this.state;

		const is_valid = isValidPhoneNumber(new_phone);
		const has_expired = verification_ttl <= 0;
		const vttl = parseInt(verification_ttl / 1000);
		const tf_props = {
			"className" : "phone-tf",
			"required" : false,
			"size" : "small",
			"inputProps" : {"className" : "phone-input"},
			"type" : "text",
			"onChange" : this.handleChange,
			"InputProps" : {"sx" : {"typography" : "body2","color":"font.main"}},
			"InputLabelProps" : {"sx" : {"typography" : "body2","color":"font.main"}},
			"sx" : { "margin" : "20px 0px 20px 0px"}
		};

		return (
			<Box className="flex-col-center">
				<Typography
					className="update-toggle"
					onClick={() => {
						this.setState({ "is_open" : !is_open });
					}}
					variant="body2"
					color="primary"
					sx={{
						"&:hover" : {
							"color" : "secondary.main"
						}
					}}
				>Add/Update Phone Number</Typography>

				{
					is_open && !verification_pending &&
					<Box className="flex-col-center">
						<TextField
							{...tf_props}
							name="new_phone"
							label="New Phone Number"
							error={new_phone && !is_valid}
							helperText="Formats: (111) 222-3333, 1112223333, 111-222-3333"
						/>

						<Box sx={{"margin" : "0px 0px 20px 0px"}}>
							<Checkbox
								color="primary"
								onChange={evt => {
									this.setState({"terms_checked" : evt.target.checked});
								}}
							/>
							<Typography
								variant="body3"
								color="font.main"
							>
								I understand and accept that my phone number will only be used for account verification and requested notifications.
							</Typography>
						</Box>

						<Button
							onClick={this.handleSendVerification}
							variant="contained"
							color="primary"
							sx={{"color" : "font.button"}}
							disabled={!is_valid || !terms_checked}
						>Send Verification Code</Button>
					</Box>
				}

				{
					is_open && verification_pending &&
					<Box className="flex-col-center">
						<TextField
							{...tf_props}
							name="verification_code"
							label="Verification Code"
							disabled={has_expired}
							error={verification_code_bad}
							helperText={verification_code_bad ? "Incorrect Verification Code" : ""}
						/>

						<Button
							onClick={has_expired ? this.handleSendVerification : this.handleSubmit}
							variant="contained"
							color="primary"
							sx={{"color" : "font.button"}}
							disabled={!is_valid}
						>{ has_expired ? "Resend Verification Code" : `Verify (${vttl}s)`}</Button>
					</Box>
				}
			</Box>
		);
	}

	handleChange(evt) {
		const { name, value } = evt.target;
		this.setState({ [name] : value });
	}

	handleSendVerification() {
		const { REACT_APP_OTP_TIMEOUT } = process.env;
		const { setContext } = this.context;
		const { new_phone } = this.state;

		if (!isValidPhoneNumber(new_phone)) return;

		const verify_interval = setInterval(() => {
			const { verification_expiration } = this.state;
			const ttl = verification_expiration - new Date().getTime();
			this.setState({"verification_ttl" : ttl});
		}, 1000);

		sendVerificationToken("sms", new_phone, (err) => {
			if (err) {
				log.debug("Failed to send verification token");
				setContext({
					"snackbar_msg" : "Failed to send verification code.",
					"snackbar_sev" : "error"
				});
				return;
			}

			this.setState({
				"verification_pending" : true,
				"verification_expiration" : new Date().getTime() + parseInt(REACT_APP_OTP_TIMEOUT),
				"verification_interval" : verify_interval,
				"verification_ttl" : parseInt(REACT_APP_OTP_TIMEOUT)
			});
		});
	}

	handleSubmit() {
		const { setContext } = this.context;
		const { onSubmit } = this.props;
		const { new_phone, verification_code } = this.state;

		if (!isValidPhoneNumber(new_phone)) return;

		updatePhone(new_phone, verification_code, (err) => {
			if (err && err.response?.status !== 400) {
				if (err.response?.status === 401) {
					this.setState({ "verification_code_bad" : true });
				} else {
					log.error(err);
				}
				return;
			} else if (err?.response?.status === 400) {
				const msg = err.response.data?.message;
				setContext({
					"snackbar_msg" : msg,
					"snackbar_sev" : "error"
				});
			}

			this.setState({
				"is_open" : false,
				"new_phone" : null,
				"verification_code" : null,
				"verification_pending" : false,
				"verification_code_bad" : false
			}, onSubmit);
		});
	}
}

export default UpdatePhone;
